import React, { Component } from "react"
import MainLayout from "../components/layout/mainLayout"
import { Link } from "gatsby"
import ReactModal from "react-modal"
import {isMobile} from 'react-device-detect';

ReactModal.setAppElement("#___gatsby")

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(204, 204, 204, .8)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    maxWidth: "100vw"
  },
}

class Media extends Component {
  state = {
    isModalOpen: false,
    selectedImage: "",
  }

  handleOpenModal = img => () => {
    this.setState({ isModalOpen: true, selectedImage: img })
  }
  handleCloseModal = () =>
    this.setState({ isModalOpen: false, selectedImage: "" })

  render() {
    if (!this.props.location.state) return null;
    return (
      <>
        <MainLayout>
          <main className="Gallery">
            <div className="Gallery-header">
              <div className="Gallery-headerInformation">
                {/* <h3>Title:</h3>
                <p>Location: </p>
                <small>Designers: </small> */}
              </div>
              
            <Link className="Gallery-backButton" to="/gallery">
              Back
            </Link>
            </div>
            <div className="Gallery-wrapper">
              {this.props.location.state.images.map(data => {
                const imageToDisplay = isMobile ? data.img : data.lg;
                return (
                <img
                  src={data.img}
                  className="Gallery-galleryImage"
                  onClick={isMobile ? null : this.handleOpenModal(imageToDisplay)}
                />
              )})}
            </div>
            <ReactModal
              isOpen={this.state.isModalOpen}
              shouldCloseOnOverlayClick
              style={modalStyles}
            >
              <div className="Gallery-modalWrapper">
                <span onClick={this.handleCloseModal} className="Gallery-modalButton">close</span>
              <img
                src={this.state.selectedImage}
                className="Gallery-modalImage"
                />
                </div>
            </ReactModal>
          </main>
        </MainLayout>
      </>
    )
  }
}

export default Media
